"use strict";
!(function (factory) {
  "function" == typeof define && define.amd
    ? define(["jquery"], factory)
    : "object" == typeof exports
    ? (module.exports = factory(require("jquery")))
    : factory(jQuery);
})(function ($) {
  var spinningTimer,
    Spinner,
    Spinning = function ($element, options) {
      return (
        (this.$el = $element),
        (this.options = $.extend(
          {},
          Spinning.rules.defaults,
          Spinning.rules[options.rule] || {},
          options,
        )),
        (this.min = Number(this.options.min) || 0),
        (this.max = Number(this.options.max) || 0),
        this.$el.on({
          "focus.spinner": $.proxy(function (e) {
            e.preventDefault(),
              $(document).trigger("mouseup.spinner"),
              (this.oldValue = this.value());
          }, this),
          "change.spinner": $.proxy(function (e) {
            e.preventDefault(), this.value(this.$el.val());
          }, this),
          "keydown.spinner": $.proxy(function (e) {
            var dir = { 38: "up", 40: "down" }[e.which];
            dir && (e.preventDefault(), this.spin(dir));
          }, this),
        }),
        (this.oldValue = this.value()),
        this.value(this.$el.val()),
        this
      );
    };
  (Spinning.rules = {
    defaults: { min: null, max: null, step: 1, precision: 0 },
    currency: { min: 0, max: null, step: 0.01, precision: 2 },
    quantity: { min: 1, max: 999, step: 1, precision: 0 },
    percent: { min: 1, max: 100, step: 1, precision: 0 },
    month: { min: 1, max: 12, step: 1, precision: 0 },
    day: { min: 1, max: 31, step: 1, precision: 0 },
    hour: { min: 0, max: 23, step: 1, precision: 0 },
    minute: { min: 1, max: 59, step: 1, precision: 0 },
    second: { min: 1, max: 59, step: 1, precision: 0 },
  }),
    (Spinning.prototype = {
      spin: function (dir) {
        if (!this.$el.prop("disabled")) {
          this.oldValue = this.value();
          var step = $.isFunction(this.options.step)
              ? this.options.step.call(this, dir)
              : this.options.step,
            multipler = "up" === dir ? 1 : -1;
          this.value(this.oldValue + Number(step) * multipler);
        }
      },
      value: function (v) {
        if (null == v) return this.numeric(this.$el.val());
        v = this.numeric(v);
        var valid = this.validate(v);
        0 !== valid && (v = -1 === valid ? this.min : this.max),
          this.$el.val(v.toFixed(this.options.precision)),
          this.oldValue !== this.value() &&
            (this.$el.trigger("changing.spinner", [
              this.value(),
              this.oldValue,
            ]),
            clearTimeout(spinningTimer),
            (spinningTimer = setTimeout(
              $.proxy(function () {
                this.$el.trigger("changed.spinner", [
                  this.value(),
                  this.oldValue,
                ]);
              }, this),
              Spinner.delay,
            )));
      },
      numeric: function (v) {
        return (
          (v =
            0 < this.options.precision ? parseFloat(v, 10) : parseInt(v, 10)),
          isFinite(v) ? v : v || this.options.min || 0
        );
      },
      validate: function (val) {
        return null !== this.options.min && val < this.min
          ? -1
          : null !== this.options.max && val > this.max
          ? 1
          : 0;
      },
    }),
    ((Spinner = function (element, options) {
      (this.$el = $(element)),
        (this.$spinning = this.$el.find('[data-spin="spinner"]')),
        0 === this.$spinning.length &&
          (this.$spinning = this.$el.find('input[type="text"]')),
        (options = $.extend({}, options, this.$spinning.data())),
        (this.spinning = new Spinning(this.$spinning, options)),
        this.$el
          .on(
            "click.spinner",
            '[data-spin="up"], [data-spin="down"]',
            $.proxy(this, "spin"),
          )
          .on(
            "mousedown.spinner",
            '[data-spin="up"], [data-spin="down"]',
            $.proxy(this, "spin"),
          ),
        $(document).on(
          "mouseup.spinner",
          $.proxy(function () {
            clearTimeout(this.spinTimeout), clearInterval(this.spinInterval);
          }, this),
        ),
        options.delay && this.delay(options.delay),
        options.changed && this.changed(options.changed),
        options.changing && this.changing(options.changing);
    }).delay = 500),
    (Spinner.prototype = {
      constructor: Spinner,
      spin: function (e) {
        var dir = $(e.currentTarget).data("spin");
        switch (e.type) {
          case "click":
            e.preventDefault(), this.spinning.spin(dir);
            break;
          case "mousedown":
            1 === e.which &&
              (this.spinTimeout = setTimeout(
                $.proxy(this, "beginSpin", dir),
                300,
              ));
        }
      },
      delay: function (ms) {
        var delay = Number(ms);
        0 <= delay && (this.constructor.delay = delay + 100);
      },
      value: function () {
        return this.spinning.value();
      },
      changed: function (fn) {
        this.bindHandler("changed.spinner", fn);
      },
      changing: function (fn) {
        this.bindHandler("changing.spinner", fn);
      },
      bindHandler: function (t, fn) {
        $.isFunction(fn) ? this.$spinning.on(t, fn) : this.$spinning.off(t);
      },
      beginSpin: function (dir) {
        this.spinInterval = setInterval(
          $.proxy(this.spinning, "spin", dir),
          100,
        );
      },
    });
  var old = $.fn.spinner;
  return (
    ($.fn.spinner = function (options, value) {
      return this.each(function () {
        var data = $.data(this, "spinner");
        data ||
          ((data = new Spinner(this, options)), $.data(this, "spinner", data)),
          "delay" === options || "changed" === options || "changing" === options
            ? data[options](value)
            : "step" === options && value
            ? (data.spinning.step = value)
            : "spin" === options && value && data.spinning.spin(value);
      });
    }),
    ($.fn.spinner.Constructor = Spinner),
    ($.fn.spinner.noConflict = function () {
      return ($.fn.spinner = old), this;
    }),
    $(function () {
      $('[data-trigger="spinner"]').spinner();
    }),
    $.fn.spinner
  );
});
